import { FeaturedContent as FeaturedContentComponent } from 'components/FeaturedContent';

import type { FeaturedContent as TFeaturedContent } from 'server/repository/hygraph/generated/graphqlTypes';
import type { ISharedTemplateProps } from 'templates/TemplateMapper/types';
import { FeaturedContentItem } from '../../components/FeaturedContent/FeaturedContent';

interface IFeaturedContent extends ISharedTemplateProps {
  templateProps: TFeaturedContent;
}

export const FeaturedContent = ({
  templateProps: { id, title, superText, items, dictionary },
}: IFeaturedContent) => {
  return (
    <FeaturedContentComponent
      id={id}
      title={title}
      superText={superText}
      items={items as FeaturedContentItem[]}
      dictionary={dictionary}
    />
  );
};
